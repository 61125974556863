<template>
  <div class="authorization">
    <div class="authorization-list" v-for="(item, index) in users" :key="index">
      <div>
        <img :src="item.avatarUrl" alt="" />
        <span>{{ item.nickName }}</span>
      </div>
      <p @click="disabledUser(index)">解绑</p>
    </div>

    <div class="none_bg" v-if="users.length == 0">
      <img src="../../assets/img/none_bg.png" alt="">
    </div>
  </div>
</template>

<script>
import { getWXUsers, untieMember } from "../../api/personal.js";
export default {
  created() {
    this.getWXUsers();
  },
  data() {
    return {
      users: [],
    };
  },
  methods: {
    getWXUsers() {
      getWXUsers().then((res) => {
        if (res.data.code == 20000) {
          this.users = res.data.data.memberList;
        }
      });
    },

    disabledUser(index) {
      this.$confirm("确定解绑该用户吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          var wxId = this.users[index].id;
          untieMember({
              id:wxId,
          }).then((res) => {
            if (res.data.code == 20000) {
              this.getWXUsers();
              this.$message.error(res.data.message);
            }
          });
        })
        .catch(() => {
          console.log("取消此操作");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.authorization {
  width: 100%;
  height: 592px;
  background-color: #ffffff;
  padding: 30px;
  overflow: auto;
  .authorization-list {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    div {
      display: flex;
      align-items: center;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
      }
      span {
        color: #333333;
        font-size: 14px;
      }
    }
    p {
      color: #123e90;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .none_bg{
    width:100%;
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    img{
      width:200px;
      height:200px;
    }
  }
}
</style>